h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
}

h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.menu_hours,
.menu_team,
.menu_reports,
.menu_documents {
  .ant-dropdown-menu {
    max-height: 500px;
    overflow: auto;
  }
}

button,
a {
  &.green {
    color: #00a651;
  }

     &.gray {
       color: #9a9a9a;
     }

  &.red {
    color: #ed6e5d;
  }

  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.tag {
  border-radius: 5px;
  font-size: 11px;
  line-height: 10px;
  padding: 4px 6px;
  display: inline-block;

  &.small {
    font-size: 9px;
  }

  &.blue {
    background: #b0ecf4;
  }

  &.red {
    background: #ed6e5d;
    color: white;
  }

  &.green {
    background: #00a651;
    color: white;
  }

  &.blue-light {
    background: #e2e6ef;
  }

  &.dark-blue {
    background: #7ccdd8;
    color: white;
  }

  &.dark-purple {
    background: #9799ff;
    color: white;
  }

  &.orange {
    background: #ffcf97;
  }

  &.square {
    border-radius: 2px;
  }
}

// *:not(input, textarea, select) {
//   user-select: none;
// }

.datePicker {
  input {
    text-align: center;
  }
}

.modal-constraints {
  .ant-modal-confirm-body {
    margin-top: 65px;

    .ant-modal-confirm-title {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 25px;
      color: white;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0.01em;

      background-image: repeating-linear-gradient(-45deg, #ff9d0b, #ff9d0b 15px, #ffaf39 15px, #ffaf39 30px);

      background-image: repeating-linear-gradient(-45deg, #ff9d0b 0 15px, #ffaf39 15px 30px);
    }

    p {
      font-size: 14px;
      line-height: 22px;
    }
  }

  .ant-modal-confirm-btns {
    button {
      &.ant-btn:not(.ant-btn-primary) {
        background: #e2e6ef;
        border-color: #e2e6ef;
        color: #273140;
      }

      &.ant-btn-primary {
        background: #ffaf39;
        border-color: #ffaf39;
      }
    }
  }
}

.modal-danger {
  .ant-modal-confirm-body {
    margin-top: 65px;

    .ant-modal-confirm-title {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 25px;
      color: white;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0.01em;

      background-image: repeating-linear-gradient(-45deg, #d5494e, #d5494e 15px, #d24246 15px, #d24246 30px);

      background-image: repeating-linear-gradient(-45deg, #d5494e 0 15px, #d24246 15px 30px);
    }

    p {
      font-size: 14px;
      line-height: 22px;
    }
  }

  .ant-modal-confirm-btns {
    button {
      &.ant-btn:not(.ant-btn-primary) {
        background: #e2e6ef;
        border-color: #e2e6ef;
        color: #273140;
      }

      &.ant-btn-primary {
        background: #d5494e;
        border-color: #d5494e;
      }

      &.ant-btn-dangerous {
        background: #d5494e !important;
        border-color: #d5494e !important;
        color: #fff !important;
      }
    }
  }
}

.ant-modal-content {
  .ant-modal-confirm-body {
    .ant-modal-confirm-title {
      font-size: 18px;
      padding-bottom: 10px;
    }

    .ant-modal-confirm-content {
      font-size: 12px;
    }
  }

  .ant-modal-footer,
  .ant-modal-confirm-btns {
    button {
      font-size: 15px;
      padding: 3px 10px;
    }
  }
}

.card-shadow {
  padding: 20px;
  background: white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.center {
    align-items: center;
    text-align: center;
  }
}

.row-clickable {
  cursor: pointer;
}

.no-print {
  @media print {
    display: none !important;
  }
}

// react-calendar-heatmap

.color-empty {
  fill: rgb(236, 236, 236);
}

.color-scale-1 {
  fill: #00a651;
}

.color-scale-1-bis {
  fill: #ed6e5d;
}

.react-calendar-heatmap-month-label {
  font-size: 10px;
  fill: grey;
  transform: translateY(-2px);
}

// ChartJS

.chartjs-legend {
  list-style-type: none;
  font-size: 0.875rem;
  width: fit-content;
  margin: 0.5rem auto;

  & li {
    padding: 0.25rem;
  }

  & .legend-item {
    height: 1.5rem;
    display: flex;

    & .label {
      width: auto;
    }

    & span {
      cursor: pointer;
      margin-right: 0.75rem;
      border-radius: 1.25rem;
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}

.app-hours-container {
  outline: none !important;

  &:focus-visible,
  &:focus,
  &:active {
    outline: none;
    outline-width: 0;
    box-shadow: none;
    border: none;
  }
}

.virtual-table-cell {
  border-bottom: 1px solid #f0f0f0;
  white-space: nowrap;

  &.virtual-table-cell-fix-left {
    background: white;
    z-index: 1;
  }

  &.virtual-table-cell-fix-left-last {
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
  }
}

.ant-table-thead {
  >tr {
    >th {
      font-size: 15px;
      line-height: 15px;
      letter-spacing: -1px;
      border-bottom: 1px solid #ccc !important;
    }
  }
}

.ant-dropdown {
  .ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical {
    padding: 10px 0;
    min-width: 200px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);

    >.ant-menu-item {
      height: 20px;
      line-height: 20px;
    }
  }
}

.app-hours-manage-container {
  div:not(.no-print)>.ant-affix {
    z-index: 11;
  }

  .ant-spin-container {
    min-width: 994px;
  }

  &.day .ant-spin-container {
    overflow: hidden;
  }
}

.dual-page {
  display: flex;
  min-height: 100vh;
  overflow: hidden;

  >.left {
    flex: 1;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 45px 30px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: column;

    img {
      width: 250px;
      height: 250px;
      margin-bottom: 30px;
      position: relative;
    }

    h2 {
      font-weight: bold;
      font-size: 40px;
      line-height: 60px;
      text-align: center;
      color: #fff;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      position: relative;
      max-width: 500px;
      width: 100%;
    }

    i {
      color: white;
      position: relative;
      font-size: 16px;
    }

    &:before {
      content: '';
      position: absolute;
      display: flex;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(8, 69, 38, 0.3);
    }
  }

  >.right {
    flex: 1;
    background: white;
    max-width: 744px;
    width: 100%;
    padding: 45px 30px;
    display: flex;
    justify-content: center;
    max-height: 100vh;
    overflow: auto;
    align-self: center;

    >form {
      max-width: 500px;
      width: 100%;

      a {
        font-size: 12px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #00a651;
      }

      .shyfter-logo {
        width: 210px;
        display: block;
        margin: 0 auto;
      }

      h1 {
        font-weight: 700;
        font-size: 24px;
        padding-bottom: 10px;
      }

      p {
        font-size: 16px;
        color: #7f8894;
      }

      .fields {
        margin-top: 50px;
        position: relative;

        .ant-row.ant-form-item {
          margin-bottom: 25px;
        }

        label {
          font-weight: bold;
          font-size: 18px;
          line-height: 14px;
        }
      }

      button[type='submit'] {
        width: 100%;
        font-size: 18px;
        line-height: 26px;
      }

      .generatedBy {
        display: block;
        font-size: 11px;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}

.ant-table-wrapper {
  overflow: auto;
}

#root {
  white-space: pre-line;
}

#react-joyride-portal .react-joyride__overlay {
  z-index: 999;
}

.ant-modal.modal-danger {
  .ant-modal-close-x {
    color: white;
    font-size: 20px;
    width: auto;
    height: auto;
    line-height: 72px;
    padding-right: 15px;
  }

  .ant-modal-header {
    padding: 25px;
    background-image: repeating-linear-gradient(-45deg, #d5494e, #d5494e 15px, #d24246 15px, #d24246 30px);

    background-image: repeating-linear-gradient(-45deg, #d5494e 0 15px, #d24246 15px 30px);

    .ant-modal-title {
      color: white;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0.01em;
    }
  }

  .ant-modal-body {
    p {
      font-size: 14px;
      line-height: 22px;
    }
  }

  .ant-modal-footer {
    button {
      font-size: 15px;
      padding: 3px 10px;

      &.ant-btn:not(.ant-btn-primary) {
        background: #e2e6ef;
        border-color: #e2e6ef;
        color: #273140;
      }

      &.ant-btn-primary {
        background: #d5494e;
        border-color: #d5494e;
      }
    }
  }
}

.cluster-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50px;
  border: 1px solid white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  background-color: #273140;
}

.ant-dropdown-menu-title-content .is-active {
  color: #00a651;
  font-weight: bold;
}

.ant-row.ant-form-item.one-line .ant-form-item-label>label,
.ant-form-item.one-line .ant-form-item-label>label {
  font-size: 13px !important;
}

.row-with-helper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.accept_terms {
  display: block;
  font-size: 11px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .dual-page {
    flex-direction: column;

    >.left {
      display: none;
    }

    >.right {
      align-items: center;
    }
  }

  .card-shadow {
    padding: 0
  }
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.full-width-chrome-picker {
  width: 100% !important;
}

.badge {
  padding: 3px 12px;
  border-radius: 100px;
}

.badge-uppercase {
  padding: 4px 15px;
  border-radius: 5px;
  font-size: 11px;
  text-transform: uppercase;
}

.w-100 {
  width: 100%;
}

.bold {
  font-weight: bold;
}

.flex-1 {
  flex:1
}

.flex-2 {
  flex:2
}

.hidden {
  display: none;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.iac-filters-menu-group {
  max-height: 250px;
  overflow-y: auto;

  .ant-dropdown-menu-item-group-title {
    display: none;
  }
}

.iac-date-presets {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px 12px;
  gap: 5px;
}