@import '~antd/dist/antd.less';

@green: #00a651;
@blue-light: #e2e6ef;
@blue: #273140;
@grey: #7f8894;
@red: #ed6e5d;

// -----------------------------
// * Form
// -----------------------------

.ant-form-vertical {
  .ant-form-item {
    margin-bottom: 15px;
    .ant-form-item-label {
      padding: 0 0 5px;
    }
  }
}

.ant-form-large .ant-form-item-label {
  > label {
    height: auto;
    font-size: 14px;
    padding-bottom: 5px;
  }
}

.ant-row.ant-form-item.one-line,
.ant-form-item.one-line {
  display: flex;
  align-items: center;
  flex-direction: row;
  .ant-form-item-label > label {
    font-size: 12px;
    padding: 0;
    padding-right: 8px;
  }
  .ant-form-item-control-input {
    min-height: 30px;
  }
}

.form-items-group.one-line {
  display: flex;
  align-items: center;
  > * {
    margin: 0 5px 0 0;
  }
  margin-bottom: 5px;
}

.margin-0 {
  margin: 0 !important;
}

// -----------------------------
// * Timepicker
// -----------------------------

.ant-picker-footer {
  display: block;
}

// -----------------------------
// * Radio group (solid)
// -----------------------------

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)  {
  color: white;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: white;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)  {
  color: white;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: white;
}

.ant-radio-group.light .ant-radio-button-wrapper {
  color: #808893;
}

.ant-radio-group-solid.light .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: @blue-light;
  border-color: @blue-light;
  color: inherit;
}

.ant-radio-group-solid.light .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: @blue-light;
}

.ant-radio-group.block {
  width: 100%;
  display: flex;

  .ant-radio-button-wrapper {
    flex-grow: 1;
    text-align: center;
  }
}

// -----------------------------
// * Checkbox
// -----------------------------

.ant-checkbox-checked .ant-checkbox-inner {
  background: white;
  &:after {
    border-right-color: @primary-color;
    border-bottom-color: @primary-color;
  }
}

.checkbox-required {
  .ant-checkbox-inner::after {
    content: '*';
    font-size: 16px;
    font-weight: bold;
    border: none;
    transform: none;
    line-height: 7px;
    color: red;
  }
}

// -----------------------------
// * Select
// -----------------------------

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: @blue-light;
  font-weight: inherit;
}

.ant-select-dropdown {
  border: 1px solid @blue-light;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0;
}

// -----------------------------
// * Button
// -----------------------------

.ant-btn {
  height: auto;
  padding: 4px 8px;
}

.ant-btn-lg {
  padding: 8px 25px;
  height: auto;
}

.ant-btn-md {
  padding: 10px;
  height: auto;
}

.ant-btn-sm {
  padding: 2px 4px;
  height: auto;
}

.ant-btn.ant-btn-circle.action {
  color: @grey;
  border-color: @grey;
  min-width: auto;
  padding: 0 3px;
  border-width: 0;
  background: none !important;

  &.ant-btn-dangerous {
    color: @red;
  }

  &[disabled] {
    opacity: 0.3;
  }

  i {
    font-size: 16px;
    line-height: 12px;
  }
}

.action > .ant-btn.ant-btn-circle[disabled] {
  background: none;
  border: none;
  padding: 0 3px;
}

.ant-btn.grey {
  border-color: @grey;
  color: @grey;
}

// -----------------------------
// * Tooltip
// -----------------------------

.ant-tooltip-disabled-compatible-wrapper {
  display: flex !important;
}

// -----------------------------
// * Link
// -----------------------------

a.highlight,
button.highlight {
  color: @green;
}

// -----------------------------
// * Link
// -----------------------------

.ant-avatar {
  background-color: @blue;
  margin-right: 5px;
}

// -----------------------------
// * Drawer
// -----------------------------

.ant-drawer-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: @blue;
}

.ant-drawer-body {
  margin-bottom: 90px;

  .actions-container {
    position: absolute;
    left: 0;
    padding: 25px;
    width: 100%;
    margin: 0;
    bottom: 0;
    background: white;
    box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.15);

    .actions {
      width: 100%;
      display: flex;
      button {
        flex-grow: 1;
        &:nth-child(n + 2) {
          margin-left: 10px;
        }
      }
    }
  }
}

// -----------------------------
// * SPIN
// -----------------------------

.ant-spin-dot-item {
  background-color: @green;
}

// -----------------------------
// * Menu
// -----------------------------

.ant-menu-item-selected {
  background: #f9f9f9;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #f9f9f9;
}

.ant-dropdown-menu-sub {
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}

.ant-menu-item-group .ant-menu-item-group-title {
  text-transform: uppercase;
  color: @green;
  font-size: 14px;
}

.ant-menu-submenu-title {
  padding-left: 18px !important;
  text-transform: uppercase;
  color: @green;
  font-size: 14px;
  &:hover {
    color: @green;
  }

  .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-arrow::after  {
    background: @green !important;
  }
}

.ant-menu-submenu-open {
  .ant-menu-item {
    padding-left: 28px !important;
    margin: 0 !important;
  }
}

// -----------------------------
// * Table
// -----------------------------

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: visible;
}

.row-dragging .drag-visible {
  visibility: visible;
}

// -----------------------------
// * Image
// -----------------------------

.ant-image {
  cursor: pointer;
  border-radius: 3px;
  overflow: hidden;
  &:hover {
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }
  .ant-image-img {
    object-fit: cover;
  }
}

// -----------------------------
// * Badges
// -----------------------------

.ant-badge-count {
  padding: 0 6px;
  line-height: 17px;
  height: 16px;
  margin-left: 5px;
}

// -----------------------------
// * General
// -----------------------------

::selection {
  color: inherit;
  background-color: @text-selection-bg;
}

@primary-color: #29313F;@height-base: 30px;@font-size-base: 13px;@font-size-sm: 10px;@font-family: 'Poppins', -apple-system, BlinkMacSystemFont, sans-serif;@btn-primary-bg: #00A651;@btn-font-weight: 800;@btn-default-border: #00A651;@btn-default-color: #00A651;@btn-font-size-sm: 12px;@btn-shadow: none;@btn-primary-shadow: none;@btn-text-shadow: none;@border-radius-base: 3px;@border-color-base: #E2E6EF;@radio-button-color: @primary-color;@text-selection-bg: #DCDCDC;@table-header-bg: #F7F9FF;@table-selected-row-bg: #F3FEF8;@dropdown-menu-bg: #F7F7F7;@back-top-bg: white;@badge-color: #00A651;@form-element-background: #f7f8fb;@input-bg: @form-element-background;@input-color: #626a7c;@select-background: @form-element-background;@picker-bg: @form-element-background;